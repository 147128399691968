.svg-container {
    position: relative;
    width: 100%;
    height: 200px;
}
  
.draw-line {
    stroke-dasharray: 1000; /* large enough value to cover the entire path */
    stroke-dashoffset: 1000; /* same as stroke-dasharray */
    animation: draw-line 10s infinite; /* adjust time as needed */
}
  
@keyframes draw-line {
    from {
        stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
}
  